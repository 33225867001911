/* colors */
$rabitPurple: #4A46B8;
$rabitLightPurple: #B7B5DE;
$red: #F11320;
$green: #10DDB5;
$yellow: #FCC129;

$black1: #222222;
$black2: #343434;
$black3: #444444;
$gray1: #666666;
$gray2: #8E8E8E;
$gray3: #C1C1C1;
$lightGray1: #DDDDDD;
$lightGray2: #EBEBEB;
$lightGray3: #F9F9F9;
$white: #FFFFFF;

/* font */
$roboto: 'Roboto', sans-serif;

$breakpoints-min: (
    'mobile-medium': (min-width: 321px),
    'mobile-large': (min-width: 376px),
    'mobile-extra-large': (min-width: 426px),
    'tablet': (min-width: 577px),
    'tablet-large': (min-width: 769px),
    'desktop': (min-width: 1025px),
    'desktop-medium': (min-width: 1201px),
    'desktop-large': (min-width: 1441px),
    'desktop-extra-large': (min-width: 1921px),
);
$breakpoints-max: (
    'mobile-small': (max-width: 320px),
    'mobile-medium': (max-width: 375px),
    'mobile-large': (max-width: 425px),
    'mobile-extra-large': (max-width: 576px),
    'tablet': (max-width: 768px),
    'tablet-large': (max-width: 1024px),
    'desktop': (max-width: 1200px),
    'desktop-medium': (max-width: 1440px),
    'desktop-large': (max-width: 1920px)
);
$width-value: (
    'mobile-small': 320,
    'mobile-medium': 320,
    'mobile-large': 375,
    'mobile-extra-large': 425,
    'tablet': 576,
    'tablet-large': 768,
    'desktop': 1024,
    'desktop-medium': 1200,
    'desktop-large': 1440,
    'desktop-extra-large': 1920,
)