* {
  font-family: $roboto;
  font-size: 14px;
  box-sizing: border-box;
  letter-spacing: -0.022em;
  margin: 0;
}

a,
a:link,
a:visited,
a:active,
a:hover {
  color: inherit;
  text-decoration: inherit;
}

input:focus-visible {
  outline: none;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none !important;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @include flex-center($direction: column);

  & > * {
    width: 100%;
  }
}

#header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 12px 20px;
  height: 56px;
  @include font-color();
  @include flex-center($justifyContent: space-between);
  .rabit-logo{
    height: 32px;
    object-fit: contain;
  }
  .rabit-logo:hover {
    cursor: pointer;
  }
  .rabit-menu{
    @include flex-center($justifyContent: space-between);
  }
}

#search {
  height: 100%;
  background-color: white;
  color: black;
  border-radius: 5px;
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .MuiInput-underline:after {
    border-bottom: none;
  }
  div {
    height: 100%;
  }
}

#footer {
  @include font-color($default: true);
  @include flex-center($alignItems: flex-end);
  height: 125px;
  margin-bottom: 25px;
  #copyright {
    width: 100%;
    text-align: center;
  }
}

#login-box {
  width: 500px;
  height: 400px;
  @include flex-center($justifyContent: space-around, $direction: column);
  padding: 40px;
}

#profile-box {
  width: 500px;
  @include flex-center($justifyContent: space-around, $direction: column);
  padding: 40px;
}

.page-content {
  flex-grow: 2;
  @include flex-center($direction: column);
  padding: 50px 75px;
  .MuiTableRow-hover:hover {
    cursor: pointer;
  }
}

.users-page, .search-page {
  justify-content: flex-start;
  align-items: flex-start;
}

.user-page {
  justify-content: flex-start;
  h2 {
    font-size: 1.5rem;
  }
}

.main-page {
  justify-content: flex-start;
  h2 {
    font-size: 1.5rem;
  }
  h5 {
    width: 100%;
    height: 64px;
    font-size: 1.5rem;
    @include flex-center;
  }
  .main-menu-button {
    font-size: 0.875rem;
    height: 40px;
    width: 125px;
    padding: 5px 0;
    line-height: 100%;
  }
}

.MuiPagination-root {
  @include flex-center;
}

.MuiDialog-container {
  & > div {
    padding: 10px 0;
  }
}