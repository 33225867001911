@mixin flex-center($justifyContent: center, $alignItems: center, $direction: row) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $direction;
}

@mixin font-color($default: false) {
  @if ($default) {
    background-color: $white;
    div {
      color: $rabitPurple;
    }
  } @else {
    background-color: $rabitPurple;
    div {
      color: $white;
    }
  }
}

@mixin border($color: white, $width: 1px, $style: solid, $top: false, $bottom: false, $left: false, $right: false, $none: false, $all: false) {
  @if ($top) {
    border-top: $color $style $width;
  }
  @if ($bottom) {
    border-bottom: $color $style $width;
  }
  @if ($left) {
    border-left: $color $style $width;
  }
  @if ($right) {
    border-right: $color $style $width;
  }
  @if ($none) {
    border: none;
  }
  @if ($all) {
    border: $color $style $width;
  }
}

@mixin border-troubleshoot {
  @include border($color:red, $all: true);
  * { @include border($color:red, $all: true) }
}